import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/review.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1cd739cb4d7b76f9c0d9de287c4cc042/1d499/ragnarok-title.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.37065637065637%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADH0lEQVQozx2S20+bBRiHvz/CK+MpYTEu1ZjpYnBqDNO4mE3nso1ljDItzCHl0I5CGVBAWqC0pYOWHjiVAm2hByhrV6C1a1MwLRZhTg7ZFJiuZksM8c7rxy9e/JL36n1/z5tHKKvv4muVnutKLTJVLzUaO02GOdrsixhnV7FHtnDc22U6vsdK9jH5vWfEcoe4Yju4wllsc2k80Rz+5TyB+CZCRYuF+oEQNzpHqPl+FKVxFrnWJc4O+sbvoh1ZpNGeRDOeZmh6CfPUCt22EL7EHol8gdnENgvpHe5nd7FOxxHOlTdQ2TkhxoW0eYiKFitXmyxINZNoZ/KMLR8yFttnLP6UiR/+wBrexb2yz1SqgPv+EyZiuzjn19FZ/NS0ORDq+/3c1HmQdbhQmMQ2dyLU6LzU6hdotiWpVhto0drwrT5jMnGAbizOsD/HyNLvGPybdDkTtFnuoewL0myOIDQORjCEt6m8PUx5nR7VwAItQ1F63Fmu3VTzzttv8fGpD+mwhNFbZhkN5TH51rFFRcToDgOBLQy+DfElGRSGeYRTpy/w6ZcVXJapuPxNE9I6HXXdLtSDIY5LJBS99iolHxRT2z6IullFn9khEvjRTa0RzD/Hu3qA2hJDZQqJhXQIZy9V8fl5KWfEpWcvyqiq13BdrqFMWoXkjdc59sqLfFbyCfKGDk5/9D7Khmrc0Q3mc38RfnCEO7VPv2cVsydJ+2AQwRpMYhgP0utcpN3kpbHVRLVMjrSskqJjRf/nxJsSurtNnDwuobT0Chv7BZKH/7L86xGeH5+Kav2M3h3H6M0iaC3TaIyjuO7mcAYzPBQ9W8/kSW8VuFF7ixMn3+WLC19hsQxz7eIVvpUrSD/+G9/abyR/KZB9dERq+x9iIn7qwXOEpZ8OiK7tYQ1k6BOvDIkK9Iwv0+9e4rbeiVxjwjAa4FarFrs/iUJnxzi3xlzmCfKeGc5V6/iuy4VSN0PXnQCCK5onsfkngcwjnIvrohIpzL4ktvksdZ1WpI39nBFdLTlfTqt5BkXvBAPeFI7IQ6bi26Jek7zw3lVeKi7l5eJL/AfkyFGsYI1snQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Ragnarok Title",
            "title": "Ragnarok Title",
            "src": "/static/1cd739cb4d7b76f9c0d9de287c4cc042/e3189/ragnarok-title.png",
            "srcSet": ["/static/1cd739cb4d7b76f9c0d9de287c4cc042/a2ead/ragnarok-title.png 259w", "/static/1cd739cb4d7b76f9c0d9de287c4cc042/6b9fd/ragnarok-title.png 518w", "/static/1cd739cb4d7b76f9c0d9de287c4cc042/e3189/ragnarok-title.png 1035w", "/static/1cd739cb4d7b76f9c0d9de287c4cc042/44d59/ragnarok-title.png 1553w", "/static/1cd739cb4d7b76f9c0d9de287c4cc042/1d499/ragnarok-title.png 1632w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let me make it absolutely clear. God of War: Ragnarok is an amazing game. If you enjoyed the first game at all, you'll enjoy this one too. All of your favorite characters are back, hitting all the same notes, and there's a `}<em parentName="p">{`large`}</em>{` cast of new characters that you'll maybe get to know.`}</p>
    <p>{`And this largeness is really Ragnarok's biggest sin. It's story often feels a little unconnected at times. Unlike 2018, with its very compact and excellently written and acted characters, here we get that same cast...and so many more, very few of whom you'll get to know super well. It's story is basically the ambiguous goal of "keep Kratos alive." The game does start out with a bang. Thor and Odin (Gerry Bertier from Remember the Titans and Richard Freaking Schiff respectively) show up at your doorstep and you have the most awkward conversation of all time. Things go exactly as you'd expect and Kratos and Thor duke it out. It's a way better setup than the fight with Baldur in the first game, but from here we enter a string of loosely connected motives for visiting each of the realms. Don't get me wrong, every single one of these scenarios is great, I just don't think they string together very well. `}</p>
    <p>{`You'll get access to a rotating roster of companions, that's right it's not just Atreus anymore, and even take control of Atreus himself. My favorite of these sojourns is the one in Jotunheim and my favorite new minor character is Surtr, and my favorite realm to explore is Vanaheim. There's just so much to do there, and the realm itself is gorgeous, with a few different biomes.`}</p>
    <p>{`The conclusion also feels rushed. When Ragnarok inevitably begins it should be this massive battle, and on the surface it is, but the whole scenario is very short and culminates in two boss battles that leave a lot to be desired in terms of difficulty. The ending is satisfying, but there are multiple dangling threads left to be picked up in either DLC or the sequel. I think, if they wanted, they could continue the story in the Norse realms. There's still plenty of meat left on these bones.`}</p>
    <p>{`The other minor sin in the game is that it's not `}<em parentName="p">{`that`}</em>{` different. It feels like a gigantic expansion to the original. The graphics are better, but not markedly so (they're still gorgeous). The combat is also mostly the same, with even many of the weapon upgrades being the same. The big twist is now, after using these abilities a few times, you can give them minor augments to one of their stats. Yes, there is a new weapon (no I wont spoil it or the Norse origin behind it) and new runic attacks. The new weapon...was not my favorite. Outside of using it to handle environmental puzzles, I only ever really used it if it felt like the axe and the blades weren't cutting it. `}</p>
    <p>{`I've spent most of this article talking about what Ragnarok did wrong, but and even that is not a lot. It's strength remains it's core cast of characters and their relationships. Kratos and Atreus, Atreus and Sindri, Freya and...everyone. All these relationships have changed and all for the better. Kratos no longer just grunts at Atreus, and I don't remember him calling him "boy" the entire time. They fight, they get along, it feels much more like a full father/son relationship this time around. Meanwhile Sindri has changed into a surrogate father/fun uncle to Atreus and there's some great stuff with the two of them as well.`}</p>
    <p>{`The game also runs butter smooth in any of it's graphics mode with zero bugs. This is a welcome change since lately that really doesn't seem to be the case for multiple AAA releases. Heck, I'm like three hours into The Callisto Protocol and I've experienced frame rate drops and a crashing bug.`}</p>
    <h3>{`Final Thoughts`}</h3>
    <p>{`I'll reiterate; Ragnarok is absolutely fantastic and worth every minute of your time. In any other year it would crush every other game for GOTY easily, including Horizon: Forbidden West which was another remarkable sequel. Sadly it will lose to Elden Ring this year. But if God of War 2018 was an easy A+, Ragnarok is an incredibly solid A. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      